import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { Heading, H2, Reviews, ReviewRating } from '../../../components';

import listingCss from '../ListingPage.module.scss';
import css from './SectionReviews.module.scss';

const SectionReviews = props => {
  const { reviews, reviewsAverage, fetchReviewsError } = props;

  // if (!(reviews.length > 0 && reviewsAverage)) {
  //   return null;
  // }

  return (
    <div className={css.sectionReviews}>
      {/* <div className={css.dividerLineReviews} /> */}
      <Heading as="h2" rootClassName={listingCss.sectionHeadingWithExtraMargin}>
        <div className={`${css.row} ${listingCss.headingMarginBottom}`}>
          <div className={css.reviewRating}>
            <ReviewRating
              rating={reviewsAverage ? reviewsAverage : 0}
              className={css.rating}
              reviewStarClassName={css.star}
              showAverageRatingValue={true}
              reviewAverageValueClassName={css.averageValue}
            />
          </div>
          <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
        </div>
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={reviews} />
      {/* <div className={css.dividerLineReviews} /> */}
    </div>
  );
};

export default SectionReviews;
